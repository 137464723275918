export const isProduction = true;
export const googlemapsapikey = 'AIzaSyDT8u2W9mcuJ-uESWb9ZAcd6sd6hkmAMVs';
export const environment = Object.freeze({
  production: true,
  brand: 'fr',

  baseUrl: 'https://services.formulaacademia.bodytech.company:8002/api',
  portal: 'https://www.formulaacademia.com.br/api',

  services: {
    baseUrl: 'https://services.formulaacademia.bodytech.company',
    evoBaseUrl: 'https://evo-integracao.w12app.com.br',
  },

  sentry: {
    dsn: 'https://19efab3117b14883aab8bdb77731fb11@sentry.dev.bodytech.company/12',
    enabled: true,
    environment: 'prod',
    release: 'bo-frportal-__SENTRY_RELEASE__',
    attachStacktrace: true,
  },

  recaptchaKey: '',

  s3buckets: {
    images: 'https://images.formulaacademia.bodytech.company',
    medias: 'https://medias.formulaacademia.bodytech.company',
    terms: 'https://terms.formulaacademia.bodytech.company'
  },

  s3Region: 'us-east-1',
  s3Environment: 'production.formulaacademia.bodytech.company'

});
